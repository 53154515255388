
@import "../assets/fonts/lato/stylesheet.css";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    outline: none;
    border: none;
}

html,body {
    height: 100% !important;
    width: 100%;
}

body {
    font-family: 'Lato';
    letter-spacing: 1px;
}


@media (min-width: 1400px) {
    .container {
        max-width: 1160px; 
    }
    .padding-0 {
        padding-left: 0;
        padding-right: 0;
    }
}
.commonSection-padding {
    padding-block: var(--commonSection-space);
}
.sectionHeading {
    text-align: center;
    margin-bottom: var(--commonSection-space);
}
  
.sectionHeading h5 {
    color: var(--theme-color);
    font-size: var(--section-heading);
    font-weight: 500;
    margin-bottom: var(--nearMedium);
    text-transform: uppercase;
}
.sectionHeading p {
    color: var(--text-color);
    font-size: var(--para-font);
    margin-inline: auto;
    max-width: 60%;
}
@media screen and (max-width:991px) {
    .sectionHeading p {
        max-width: 100%;
    } 
    .sectionHeading {
        margin-bottom: 10px;
    }
}
::selection {
    background-color: var(--theme-color);
    color: white;
}
/* ---------------- variable ------------------ */
:root {
    --theme-color:#00b3aa;
    --white-color:#fff;
    --heading-color:#252525;
    --lightSkyBlue-color:#f1f4f5;
    --text-color:#696969;
    --gray-color:#f2f2f2;
    --border-color:#e2e2e2;
    --inner-page-heading-color:#403e3e;
/* ---------------- Font Size ------------------ */

    --navLink-font:15px;
    --title-font:24px;
    --logo-font:35px; 
    --section-heading:35px;
    --inner-page-heading:45px;
    --para-font:15px; 

/* ---------------- Font Size ------------------ */

/* ---------------- Spacing ------------------ */
    --smallest:14px;
    --medium:30px;
    --nearMedium:35px;
    --nearlarge:60px;
    --large-space:80px;
    --extraLarge-space:110px;
    --commonSection-space:100px;

/* ---------------- Spacing ------------------ */
}

/* ---------------- variable ------------------ */

/* ---------------- header work Start ----------------  */

.header {
    background-color: var(--theme-color);
    padding: var(--smallest) 0;
    position: sticky;
    top: 0;
    z-index: 9999;
    transition: width .3s linear;
}

.header .main-navbar .logoDiv {
    display: flex;
    align-items: center;
}

.header .main-navbar .logoDiv img{
    width: 75px;
    object-fit: cover;
}

.header .main-navbar .logoDiv .LogoName {
    font-size: var(--logo-font);
    color: var(--white-color);
    margin-left: var(--smallest);
    font-weight: 500;
}

.header .main-navbar .logoDiv .LogoName .logoBoldX {
    font-weight: 600;
}
.header .main-navbar #basic-navbar-nav .navbar-listingDiv .navbar-listing {
    padding: 8px;
}
@media screen and (min-width:991px) {
    .header .main-navbar #basic-navbar-nav .navbar-listingDiv .navbar-listing:not(:first-child) {
        margin-left: 50px;
    }
}

.header .main-navbar #basic-navbar-nav .navbar-listingDiv .navbar-listing .navbar-link {
    color: var(--white-color);
    font-size: var(--navLink-font);
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    padding: 3px 0;
    overflow: hidden;
}

.header .main-navbar #basic-navbar-nav .navbar-listingDiv .navbar-listing .navbar-link::after {
    position: absolute;
    bottom: 0px;
    left: 0;
    margin: auto;
    width: 100%;
    height: 2px;
    transition: transform 0.4s ease-in-out;
    transform: translateX(-110%);
    content: '';
    background: rgb(255, 255, 255);
}
.header .main-navbar #basic-navbar-nav .navbar-listingDiv .navbar-listing .navbar-link:hover::after {
    transform: translate(0);
    transition: transform 0.4s ease-in-out;
}
.header .main-navbar #basic-navbar-nav .navbar-listingDiv .navbar-listing .navbar-link.active-page::after {
    transform: translateX(0%);
}
.mobile-img {
    display: none;
}
@media screen and (max-width:991px) {
    .mobile-img {
        display: block;
        margin-inline: auto;
    }
    .desktop-mobile {
        display: none;
    }
}
.headerScrolled {
    box-shadow: 0 0 20px rgb(0 0 0 / 9%);
}
.headerScrolled .main-navbar .logoDiv img {
    width: 60px;
    transition: width .3s linear;
}
.headerScrolled .main-navbar .logoDiv .LogoName {
   display: none;
}

/* ---------------- header work End ----------------  */

/* --------------- Footer Work Start ------------------ */

.main-footer .footerRow{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0;
    color: var(--text-color);
    font-size: var(--para-font);
    flex-wrap: wrap;
    text-align: center;
}
.main-footer .footerRow ul li a {
    color: var(--text-color);
    position: relative;
    padding-inline: 30px;
}
.main-footer .footerRow ul li:first-child {
    padding-inline-start: 0;
}
.main-footer .footerRow ul li:first-child a{
   padding-left: 0;
}
.main-footer .footerRow ul li a::after {
    position: absolute;
    content: "";
    background-color: var(--text-color);
    right: 0;
    top: 50%;
    height:30%;
    width: 2px;
    transform: translate(0, -50%);
    opacity: 0.8;
}
.main-footer .footerRow ul li:last-child a::after {
        display: none;
}  
/* --------------- Footer Work End ------------------ */

/* ---------------- Home Page Work Start ----------------  */

.heroSection {
    background-color: var(--theme-color);
    padding-block-start: var(--large-space);
    padding-block-end:200px;
    color: var(--white-color);
    position: relative;
    isolation: isolate;
    overflow: hidden;
}
.heroSection .heroSectionRow .heroSection-title {
    font-size: 102px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
} 
.heroSection .heroSectionRow .heroSection-title span{
    font-size: 84px; 
}
.heroSection .heroSectionRow .heroSection-span{
    font-size: 30px; 
    font-weight: 400;    
    display: flex;
    margin-top: 50px;
    margin-bottom: 80px;
}

.heroSection-dwLinkDiv .heroSection-dwLink {
    width: 230px;
}

.heroSection-dwLinkDiv .heroSection-dwLink:not(:last-child) {
    margin-right: 50px;
}

@media screen and (min-width:1500px) {
    .heroSection-mobileDiv::after {
        content: "";
        position: absolute;
        background-image: url("../assets/img/index-heroSectionTransperant.png");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        z-index: -1;
        inset: 0;
        width: 50%;
        height: 80%;
        left: 50%;
        top: 50px;
        transform: translate(-11% , 0);
        filter: brightness(0.5);
    }    
}
.heroSection-dwLinkDiv .heroSection-dwLink img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@media screen and (min-width:991px) {
    .heroSection-mobileDiv .heroSection-mobile {
        position: absolute;
        top: 0;
        left: 50%;
        transform:translate(35%, 35px);
        border-radius: 12px;
    }
}
.features-section .feature-card {
    border: 0;
    margin-block: var(--nearlarge);
}
.features-section .feature-card .feature-imgDiv img {
    width: 66px;
    height: 76px;
    object-fit: cover;
}
.features-section .leftFeature-card:nth-child(even) .feature-imgDiv  {
    order: -1;
}
.features-section .rightFeature-card:nth-child(odd) .feature-imgDiv  {
    order: -1;
}
.features-section .feature-card .row .feature-contantDiv .feature-contantHeading {
    font-size: var(--title-font);
    font-weight: 600;
    color: var(--theme-color);
    text-transform: uppercase;
}
.features-section .feature-card .row .feature-contantDiv .feature-contantHeading span {
    color: var(--heading-color);
}
.features-section .feature-card .row .feature-contantDiv p {
    font-size: var(--navLink-font);
    color: var(--text-color);
}

.features-section .features-sectionMobile-div img {
    border-radius: 36px;
    box-shadow: 0 0 20px #00000019;
    display: flex;
    margin-inline: auto;
}

.benefits-section {
    background-color: var(--gray-color);
}
.benefits-section .benefitsCard {
    border: 0;
    border-radius: 0;
    padding: 50px 20px;
    text-align: center;
    background-color: white;
    transition: all 0.5s;
}

/* .benefits-section .benefitsCard:hover {
 background-color: var(--theme-color);
 box-shadow: 0 0 20px rgba(0, 0, 0, 0.109);
}
.benefits-section .benefitsCard:hover .benefitsCard-title ,
.benefits-section .benefitsCard:hover .benefitsCard-contant ,
.benefits-section .benefitsCard:hover .benefitsCard-footer .benefitsCard-btn   {
    color: var(--white-color);   
} 
.benefits-section .benefitsCard:hover .benefitsCard-footer .benefitsCard-btn:hover::after {
    background-color: white;
} */

.benefits-section .benefitsCard:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.109);
    transition: all 0.5s;
   }

.benefits-section .benefitsCard .benefitsCardImg {
    width: 66px;
    object-fit: cover;
    margin-inline: auto;
}

.benefits-section .benefitsCard .benefitsCard-body {
    padding: 30px 0 0px;
}

.benefits-section .benefitsCard .benefitsCard-title  {
    font-size: var(--title-font);
    color: var(--heading-color);
    font-weight: 500;
    margin-bottom: 0;
}

.benefits-section .benefitsCard .benefitsCard-contant {
    font-size: var(--navLink-font);
    color: var(--text-color);
    font-weight: 500;
    margin-top: 15px;
}
.benefits-section .benefitsCard .benefitsCard-footer {
    background-color: transparent;
    border: 0;
    padding: 0;
}
.benefits-section .benefitsCard .benefitsCard-footer .benefitsCard-btn {
    background-color: transparent;
    color: var(--theme-color);
    font-size: var(--navLink-font);
    font-weight: 500;
    position: relative;
    overflow: hidden;
}
.benefits-section .benefitsCard .benefitsCard-footer .benefitsCard-btn::after {
    position: absolute;
    bottom: 0px;
    left: 0;
    margin: auto;
    width: 100%;
    height: 2px;
    transition: transform 0.4s ease-in-out;
    transform: translateX(-110%);
    content: '';
    background: var(--theme-color);
}
.benefits-section .benefitsCard .benefitsCard-footer .benefitsCard-btn:hover::after {
    transform: translate(0);
    transition: transform 0.4s ease-in-out;
}

.screen-sliderDiv .screen-slider-imgDiv .splide {
    padding: 0;
}
.screen-sliderDiv  li.screen-slider-imgDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}
.screen-sliderDiv  li.screen-slider-imgDiv img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.screen-sliderDiv .is-active ~ li.screen-slider-imgDiv.is-active  {
    transform: scale(1.12);
    position: relative;
}
li.screen-slider-imgDiv.is-active::after {
    position: absolute;
    content: "";
    background-image: url("../assets/img/home/slider-Shadow.png");
    /* background-size: cover; */
    background-size: contain;
    background-repeat: no-repeat;
    /* width: 100%;
    height: 10px;
    bottom: 38px;
    left: 0; */
    width: 86%;
    height: 12%;
    bottom: -16px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 9;
}
.screen-sliderDiv .splide.is-initialized, .screen-sliderDiv .splide.is-rendered {
    height: 500px;
}
.splide__track {
    height: 100%;
}


.reviewsSection {
   border-top: 1px solid var(--border-color);
}
.reviewsSection .sectionHeading {
    margin-bottom: 0;
}
.reviewsSection .card-review .reviewCard {
    border: 10px solid #f1f4f5;
    padding: 40px 60px;
}
.reviewsSection .container {
    position: relative;
    text-align: center;
}
.reviewsSection-slider .card-review .reviewCard .reviewCard-contant {
    font-size: 15px;
    color: var(--text-color);
    margin-bottom: 35px;
} 
.reviewsSection-slider .card-review .reviewCard  h6 {
    font-size: 15px;
    color: var(--theme-color);
    margin-bottom: 0px;
    font-weight: 600;
}
.reviewsSection .reviewsSection-slider .splide__track--draggable::after {
    position: absolute;
    content: "";
    background-image: url("../assets/img/home/review-quate.png");
    background-repeat: no-repeat;
    width: 90px;
    height: 100%;
    top: 0;
    left: 0;
}
.reviewsSection .reviewsSection-slider .splide__track--draggable::before {
    position: absolute;
    content: "";
    background-image: url("../assets/img/home/review-quate.png");
    background-repeat: no-repeat;
    width: 100px;
    height: 100%;
    bottom: 0;
    right: 0;
    z-index: 1;
    transform: rotate(180deg);
}
.reviewsSection .container .splide__pagination__page {
     width: 18px;
     height: 18px;
     border-radius: 100vh;
     margin-inline: 5px;
}
.dw-link-section {
    background: url("../assets/img/home/transperant-bg-img.jpg") ;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    isolation: isolate;
    padding-top: 50px;
}
.dw-link-section::after {
    position: absolute;
    content: "";
    background-color:#00b3aa;
    opacity: 0.9;
    width: 100%;
    height: 100%;
    inset: 0;
    z-index: -1;
}
.dw-link-section .dw-mobileDiv {
    display: flex;
    justify-content: center;
    background-image:url("../assets/img/home/endSection mobile.png");
    height: 370px;
    background-repeat: no-repeat;
}
.dw-link-section .dw-contantDiv h5 {
    font-size: 35px;
    font-weight: 500;
    color: var(--white-color);
}
.dw-link-section .dw-contantDiv p {
    font-size: 20px;
    color: var(--white-color);
    margin-block: 30px;
}
/* ---------------- Home Page Work Etart ----------------  */


@media screen and (max-width:1300px) {
    .heroSection .heroSectionRow .heroSection-title { 
        font-size: 90px;
    }
    .heroSection .heroSectionRow .heroSection-title span { 
        font-size: 72px;
    }
}

@media screen and (max-width:991px) {
    .heroSection {
        text-align: center;
    }
    .heroSection .heroSectionRow .heroSection-span {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .heroSection-dwLinkDiv {
        display: flex;
        justify-content: center;
        margin-bottom: 50px;
    }
    
}

@media screen and (max-width:771px) {
    .heroSection .heroSectionRow .heroSection-title { 
        font-size: 50px;
    }
    .heroSection .heroSectionRow .heroSection-title span { 
        font-size: 40px;
    }
    .reviewsSection .card-review .reviewCard {
        padding: 15px 0;
    }
    .dw-link-section .row {
        flex-direction: column-reverse;
    }
    .dw-link-section .dw-mobileDiv {
        background-position: top;
    }
}

  @media screen and (max-width:700px) {
    .header .main-navbar .logoDiv .LogoName {
        display: none;
    }
    .commonSection-padding  {
        padding-block: 30px; 
    }
    .sectionHeading {
        margin-bottom: 30px;
    } 
    .sectionHeading p {
        max-width: 100%;
        margin-bottom: 0;
        margin-top: 10px;
    }
    .features-section .feature-card {
        margin-block: 30px;
    }
    li.screen-slider-imgDiv.is-active::after {
        display: none;
    }
    .screen-sliderDiv .is-active ~ li.screen-slider-imgDiv.is-active {
        transform: scale(1);
    }
    .sectionHeading h5 {
        margin-bottom: 0;
    }
}  


/* ----------------------- Faq Page Start ----------------------- */

.innerPage-heroSection {
    background-image:radial-gradient(circle, rgb(0 179 170 / 70%), rgb(0 179 170 / 70%), rgb(0, 179, 170));
    width: 100%;
    height: 100%;
    padding: 100px 0;
    position: relative;
    margin-bottom: 72px;
}
.innerPage-heroSection::after {
    position: absolute;
    content: "";
    background-image: url("../assets/img/Faq/hero-banner.jpg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
}
.innerPage-heroSection .innerPage-heroSectionInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
}
.innerPage-heroSection .innerPage-heroSectionInfo h1 {
    font-size: var(--inner-page-heading);
    font-weight: 600;
    margin-bottom: 20px;
    text-transform: uppercase;
    text-align: center;
}  
.innerPage-heroSection .innerPage-heroSectionInfo .innerPage-breadkrumb li ,
.innerPage-heroSection .innerPage-heroSectionInfo .innerPage-breadkrumb li a {
    color: white;
    font-size: var(--smallest) + 2px ;
    text-decoration: none;
}
.innerPage-heroSection .innerPage-heroSectionInfo .breadcrumb-item + .breadcrumb-item::before {
    color: white;
    content: var(--bs-breadcrumb-divider, ">");
}
.main-faqsection {
    margin-bottom: 50px;
}
.main-faqsection .faq-heading {
    font-size: 18px;
    font-weight: 600;
    color:var(--inner-page-heading-color);
    margin-bottom: 35px;
}
.main-faqsection .faq-accordian .accordion-button::after {
    display: none;
}
.main-faqsection .faq-accordian .accordion-item {
    border: 0;
    margin-block: 5px;
} 
.accordion-button:focus , 
.accordion-button:hover {
    box-shadow: 0 0 0 0;
    border: 0;
    outline: 0;
}
.main-faqsection .faq-accordian .accordion-item .accordion-button[aria-expanded="true"] {
    background-color: var(--theme-color); 
    color: var(--white-color);
}
.main-faqsection .faq-accordian .accordion-item .accordion-button {
    font-size: 18px;
    border-radius: 5px 5px 0 0;
}
.main-faqsection .faq-accordian .accordion-collapse.collapse.show {
    background-color: #f5f5f5;
}
.main-faqsection .faq-accordian .accordion-collapse {
    font-size: var(--navLink-font); 
    color: #6d6e6e;
}
/* ----------------------- Faq Page End ----------------------- */

/* ----------------------- PRIVACY POLICY Page Start -----------------------  */

.main-privacyPolicy .main-privacyPolicyInfo {
    margin-block: 50px;
}
.main-privacyPolicy .main-privacyPolicyInfo h4 {
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 18px;
    color: var(--inner-page-heading-color);
}
.main-privacyPolicy .main-privacyPolicyInfo p {
    font-size: var(--navLink-font);
    line-height: 1.6;
    color: #6d6e6e;
}
.main-privacyPolicy .main-privacyPolicyInfo span {
    margin-top: 30px;
    display: block;
}
/* ----------------------- PRIVACY POLICY Page End ----------------------- */

/* ----------------------- Support Page Start ----------------------- */
.support-formsection {
    margin-bottom: 105px;
}
.support-formsection .support-formDiscription {
    text-align: center;
}
.support-formsection .support-formDiscription h5 {
    color:var(--inner-page-heading-color);
    font-size: var(--section-heading);
}
.support-formsection .support-formDiscription p {
    font-size: var(--navLink-font);
    color: #6d6e6e;
    margin-top: 30px;
    margin-bottom: 50px;
}
.support-formDiv .support-form input ,
.support-formDiv .support-form textarea {
    color: #333;
    font-size: 13px;
    border: 1px solid #e8e8e8;
    padding-block: 14px;
    padding-left: 15px;
    border-radius: 0;
}
.support-formDiv .support-form textarea {
    margin-top: 24px;
    /* margin-bottom: 40px; */
}
.support-formDiv .support-form input:focus ,
.support-formDiv .support-form textarea:focus {
    border: 1px solid var(--theme-color);
}
.support-formDiv .support-form input::placeholder ,
.support-formDiv .support-form textarea::placeholder {
    color: #b3b3b3;
}
.form-control:focus,
.form-control:hover {
    box-shadow: unset;
    outline: 0;
}
.support-formDiv .support-form .submitBtn {
    margin-inline: auto;
    display: flex;
    padding: 10.5px 55px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    background-color: var(--theme-color);
    border: 1px solid var(--theme-color);
    border-radius: 4px;
    transition: 0.5s;
    margin-top: 40px;
}
.support-formDiv .support-form .submitBtn:hover,
.support-formDiv .support-form .submitBtn:focus {
    background-color: white;
    border: 1px solid var(--theme-color);
    color: var(--theme-color);
    transition: 0.5s;
}
.support-locationDiv {
    margin-bottom: 60px;
}
.support-locationDiv .support-locationinfoDiv h2 {
    color: var(--inner-page-heading-color);
    font-size: var(--section-heading);
    text-align: center;
    margin-bottom: 20px;
}
.support-locationDiv .support-locationinfoDiv p {
    font-size: var(--navLink-font);
    width: min(100% , 800px);
    margin-inline: auto;
    text-align: center;
}
.support-otherInfoDiv {
    margin-top: var(--inner-page-heading);
}
.support-locationDiv .support-otherInfo {
    display: flex;
    align-items: center;
}
.support-locationDiv .support-otherInfo a, .support-locationDiv .support-otherInfo span {
   color: var(--inner-page-heading-color);
   text-decoration: none;
   margin-left: 15px;
}
/* ----------------------- Support Page End ----------------------- */

/* ----------------------- PremiumVersion Page Start ----------------------- */
.premiumVersion-benefitsSection .premiumVersion-benefitsDiv {
    text-align: center;
    padding-bottom: 50px;
    border-bottom: 1px solid #eeeeee;
}
.premiumVersion-benefitsSection .premiumVersion-benefitsDiv h2{
     font-size: 50px;
     color: var(--theme-color);
     font-weight: 500;
}
.premiumVersion-benefitsSection .premiumVersion-benefitsDiv h6{
     font-size: 45px;
     color: var(--inner-page-heading-color);
     font-weight: 300;
     margin-top: 20px;
     margin-bottom: 50px;
}
.premiumVersion-benefitsSection .premiumVersion-benefitsDiv .premiumVersion-benefitsBoxRow .premiumVersion-benefitsBox {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #edf6f6;
    padding: 15px 10px;
    color: var(--inner-page-heading-color);
    border-radius: 100vh;
}
.premiumVersion-benefitsSection .premiumVersion-benefitsDiv .premiumVersion-benefitsBoxRow .premiumVersion-benefitsBox span {
    margin-left: 10px;
    font-size:20px;
    color: var(--inner-page-heading-color);
}
.premiumVersion-infoSection {
    margin-block: 100px;
}
.premiumVersion-infoSection .main-premiumVersion-infoDiv .premiumVersion-infoDiv {
    display: flex;
    align-items: center;
    margin-block:25px;
}
.premiumVersion-infoSection .main-premiumVersion-infoDiv .premiumVersion-infoDiv .premiumVersion-info {
    padding-inline: 30px;
}
.premiumVersion-infoSection .main-premiumVersion-infoDiv .premiumVersion-infoDiv .premiumVersion-info h5   {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 10px;
    color: var(--inner-page-heading-color);
} 
.premiumVersion-infoSection .main-premiumVersion-infoDiv .premiumVersion-infoDiv .premiumVersion-info span {
    color: var(--theme-color);
}
.premiumVersion-infoSection .main-premiumVersion-infoDiv .premiumVersion-infoDiv .premiumVersion-info p {
    color: #6d6e6e;
    font-size: 14px;
}
.premiumVersion-infoSection .main-premiumVersion-infoDiv .premiumVersion-infoDiv .premiumVersion-infoDivIcon img {
    width: 100px;
    height: auto;
    object-fit: cover;
}
.premiumVersion-infoSection  .premiumVersion-infoImg img {
   width: min(100% , 300px);
   box-shadow: 0 0 40px rgba(0, 0, 0, 0.12);
   border-radius: 35px;
}
@media screen and (max-width:600px) {
    .premiumVersion-infoSection  .premiumVersion-infoImg img {
        display: flex;
        margin-inline: auto;
    } 
}
@media screen and (max-width:991px) {
     .premiumVersion-benefitsBoxRow .premiumVersion-benefitsBox  {
        margin-bottom: 25px;
    }
}

/* ----------------------- PremiumVersion Page End ----------------------- */

  .splide__arrow svg {
    display: none !important;
}

.splide__arrow.splide__arrow--prev::after,
.splide__arrow.splide__arrow--next::after {
    position: absolute;
    content: "";
    background-image:url("../assets/img/sliderArrow.png");
    background-repeat: no-repeat;
    background-size:contain;
    width: 50px; 
    height: 50px;
}
.splide__arrow.splide__arrow--prev::after {
    transform: rotate(180deg);
    left: -20px;
}
.splide__arrow.splide__arrow--next::after {
    left: 10px;
}
@media screen and (max-width:991px) {
    .heroSection {
        padding-block-end:50px;
        padding-block-start:10px;
    }
    .burger-menu {
        width: 30px;
        height: 2px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.4s ease;
        position: relative;
        padding: 0;
        flex: none;
    }
    .burger-menu:focus ,
    .burger-menu:hover {
        box-shadow: unset;
    }
    .burger-menu::after,
    .burger-menu::before {
        content: '';
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 2px;
    }
    .burger-menu::after {
        top: 9px;
        background-color: #fff;
    }
    .burger-menu::before {
        top: -10px;
        background: #fff;
    }
    .burger-menu span {
        display: none;
    }
    
    /* .burger-menu2 {
        transform: rotate(360deg);
        transition: transform 0.2s ease-in-out;
    }
    .burger-menu2::before {
        transform: rotate(90deg) translate(8px, 0px);
        transition: transform 0.2s ease-in-out;
    }
    .burger-menu2::after {
        opacity: 0;
        transition: transform 0.2s ease-in-out;
    }
    .burger-menu2 {
        transform: rotate(45deg) ;
        transition: transform 0.2s ease-in-out;
    } */
    .navbar-collapse  {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: min(60% , 350px);
        background: white;
        transform: translateX(-100%);
        transition: transform 0.5s ease;
        box-shadow: 20px 0px 20px #00000017;
    }
    .navbar-collapse.show {
        transform: translateX(0%);
        transition: transform 0.5s ease;
    }
    .header .main-navbar #basic-navbar-nav.navbar-collapse.show .navbar-listingDiv {
        height: 100%;
        padding: 29px 0px 0 20px;
    }
    .header .main-navbar #basic-navbar-nav.navbar-collapse.show .navbar-listingDiv .navbar-listing .navbar-link {
        color: var(--theme-color);
    }
}
@media screen and (min-width:992px) and (max-width:1200px) {
    .header .main-navbar #basic-navbar-nav .navbar-listingDiv .navbar-listing:not(:first-child) {
        margin-left: 20px;
    }
}

@media screen and (max-width:771px) {
    .support-formDiv .support-form .supportForm-inputDiv {
        margin-bottom: 25px;
    }
    .support-formDiv .support-form textarea {
        margin-top: 0;
    }
}
